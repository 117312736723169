import { Component } from '@verndale/core';

class Header extends Component {
  setupDefaults() {
    this.dom = {
      $nav: this.$el.find('.primary-nav'),
      $open: this.$el.find('.primary-nav__open'),
      $close: this.$el.find('.primary-nav__close')
    };
  }

  addListeners() {
    this.dom.$open.on('click', ::this.toggleMenu);
    this.dom.$close.on('click', ::this.toggleMenu);
  }

  toggleMenu(e) {
    e.preventDefault();
    this.dom.$nav.toggleClass('primary-nav--active');
  }
}

export default Header;
