import Header from './Header';
import CookieBanner from './CookieBanner';
import BlackSiteAlert from './BlackSiteAlert';

function polyfills() {
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
      value: function assign(target, varArgs) {
        'use strict';
        // eslint-disable-next-line no-undefined
        if (target === null || target === undefined) {
          throw new TypeError('Cannot convert undefined or null to object');
        }
        const to = Object(target);

        for(let index = 1; index < arguments.length; index++) {
          const nextSource = arguments[index];
          // eslint-disable-next-line no-undefined
          if (nextSource !== null && nextSource !== undefined) {
            for(const nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }
}

function global() {
  polyfills();
  new Header('.header');
  new CookieBanner('.cookie-banner');
  new BlackSiteAlert('.black-site-alert');
}

export default global;
