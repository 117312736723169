import 'lazysizes';
import create from '@verndale/core';
import global from './modules/global';
import './helpers/bg-lazyload';

//no flights
//https://www.oneworld.com/flights/timetable-search?p_p_id=timetablesearch_WAR_onetreeportlet&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_resource_id=search&p_p_cacheability=cacheLevelPage&p_p_col_id=column-1&p_p_col_pos=1&p_p_col_count=2&_timetablesearch_WAR_onetreeportlet_home=false&_timetablesearch_WAR_onetreeportlet_from=(BOS)+Edward+L.+Logan+International+Airport&_timetablesearch_WAR_onetreeportlet_fromType=STA&_timetablesearch_WAR_onetreeportlet_to=(LAD)+Quatro+de+Fevereiro+International+Airport&_timetablesearch_WAR_onetreeportlet_toType=&_timetablesearch_WAR_onetreeportlet_departureDate=09%2FOct%2F2018+&_timetablesearch_WAR_onetreeportlet_returnDateActive=on&_timetablesearch_WAR_onetreeportlet_returnDate=12%2FOct%2F2018

const modules = [
  { name: 'HeroImage' },
  { name: 'Faq' },
  { name: 'MobileDropdown' },
  { name: 'Tabs' },
  { name: 'StickyHeader' },
  { name: 'airline/AirlineHero' },
  { name: 'airline/AirlineAffiliates' },
  { name: 'airline/AirlineTiers' },
  { name: 'Lounge' },
  { name: 'LoungesList' },
  { name: 'Anchors' },
  { name: 'news-media/News' },
  { name: 'news-media/LatestNews' },
  { name: 'news-media/NewsList' },
  { name: 'news-media/NewsSubscribe' },
  { name: 'sustainability/SustainabilityList' },
  { name: 'home/Services' },
  { name: 'find-flight/FlightListing' },
  { name: 'benefits/oneworldPriority' },
  { name: 'benefits/benefitsFindLounges' },
  { name: 'benefits/oneworldPriorityResult' },
  { name: 'airline/AirlineLounge' },
  { name: 'Video' },
  { name: 'UserLocale' },
  { name: 'flexible/Accordion' },
  { name: 'flexible/NewsSubscribeFlexible' },
  { name: 'flexible/JumpLinkList' },
  { name: 'flexible/AlertBanner' },
  { name: 'LeavingPopup' },
  { name: 'SearchFlights' },
  { name: 'EligibilityForm' }
];

$(() => {
  global();

  create(modules, 'js/modules');
});
