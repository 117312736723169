import { Component } from '@verndale/core';
import tingle from 'tingle.js';

class BlackSiteAlert extends Component {
  setupDefaults() {
    this.dom = {
      $viewDetailsBtn: this.$el.find('.black-site-alert__view-btn'),
      $dismissBtn: this.$el.find('.black-site-alert__dismiss-btn'),
      $title: this.$el.find('.black-site-alert__title'),
      $modal: this.$el.find('.black-site-alert__modal'),
      $modalTitle: this.$el.find('.black-site-alert__modal-title'),
      $modalBody: this.$el.find('.black-site-alert__modal-body'),
      $modalCloseBtn: this.$el.find('.black-site-alert__modal-close')
    };
    this.url = this.$el.data('url');
    this.init();
  }

  addListeners() {
    this.dom.$viewDetailsBtn.on('click touchstart', ::this.onViewDetailsClick);
    this.dom.$dismissBtn.on('click touchstart', ::this.onDismissClick);
    this.dom.$modalCloseBtn.on('click touchstart', ::this.onModalCloseClick);
  }

  init() {
    if (!this.url) return;
    $.getJSON(this.url).then(::this.onSuccess);
  }

  onSuccess(response) {
    this.response = response;
    if (response.blackSiteEnabled) {
      $(this.dom.$title).text(response.heading)
      $(this.$el).slideDown();
    }
  }

  createModal() {
    const { heading, blackSiteHtml } = this.response;

    if (heading) this.dom.$modalTitle.text(heading);
    if (blackSiteHtml) this.dom.$modalBody.html(blackSiteHtml);

    this.dom.$modal.removeAttr('hidden');

    this.modal = new tingle.modal({
      closeMethods: ['overlay', 'escape'],
      cssClass: ['black-site-modal']
    });

    this.modal.setContent(this.dom.$modal[0]);
    this.modal.open();
  }

  onViewDetailsClick() {
    this.createModal();
  }

  onDismissClick() {
    $(this.$el).slideUp();
  }

  onModalCloseClick() {
    if (this.modal) {
      this.modal.close();
      this.modal = null;
    }
  }

}

export default BlackSiteAlert;
