import { Component } from '@verndale/core';
import cookie from 'js-cookie';

class CookieBanner extends Component {
  setupDefaults() {
    this.dom = {
      $close: this.$el.find('.cookie-banner__close'),
      $accept: this.$el.find('.cookie-banner__accept')
    };
    this.init();
  }

  init() {
    const owcookies = cookie.get('owcookies');
    if (!owcookies) {
      this.$el.fadeIn();
    }
  }

  addListeners() {
    this.dom.$close.on('click', ::this.handleClose);
    this.dom.$accept.on('click', ::this.handleAccept);
  }

  handleClose() {
    cookie.set('owcookies', 'accept', { expires: 365 });
    this.$el.fadeOut();
  }

  handleAccept() {
    cookie.set('owcookies', 'accept', { expires: 365 });
    this.$el.fadeOut();
  }
}

export default CookieBanner;
